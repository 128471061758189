import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import {
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Alert,
    AlertTitle,
    TextField,
    Button
} from '@mui/material';
import {getHousePeriodicConsumptions} from "../../../api_calls/api_calls_houses";
import Loading from "../../layout/Loading";

function HouseMonitoringPeriodicConsumptions() {
    const {id} = useParams();
    const [period, setPeriod] = useState('lastday');
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataError, setDataError] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleChange = (event) => {
        const selectedPeriod = event.target.value;
        setPeriod(selectedPeriod);
        setStartDate('');
        setEndDate('');
        if (selectedPeriod === 'customperiod') {
            // Clear the chart data
            setData([]);
            setLabels([]);
        }
    };

    const fetchCustomPeriodData = () => {
        if (startDate && endDate) {
            const formattedPeriod = `period/${startDate}/${endDate}`;
            setLoading(true);
            setDataError(false);
            getHousePeriodicConsumptions(formattedPeriod, id)
                .then(response => {
                    setLoading(false);
                    setData(response.consumption);
                    setLabels(response.labels);
                })
                .catch(error => {
                    setLoading(false);
                    setDataError(true);
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        if (period !== 'customperiod') {
            setLoading(true);
            setDataError(false);
            getHousePeriodicConsumptions(period, id)
                .then(response => {
                    setLoading(false);
                    setData(response.consumption);
                    setLabels(response.labels);
                })
                .catch(error => {
                    setLoading(false);
                    setDataError(true);
                    console.log(error);
                });
        }
    }, [period, id]);

    // Calculate the average
    const average = data?.length ? data.reduce((acc, val) => acc + val, 0) / data.length : 0;
    const color = {
        low: '#00ABE1',
        middle: '#4A78A6',
        high: '#161F6D'
    };

    const getGradientColor = (value) => {
        const max = average + 0.25 * average;
        if (value < average) {
            return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {offset: 0, color: color.low},
                {offset: 1, color: color.middle}
            ]);
        } else if (value <= max) {
            return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {offset: 0, color: color.middle},
                {offset: 1, color: color.high}
            ]);
        } else {
            return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {offset: 0, color: color.high},
                {offset: 1, color: color.high}
            ]);
        }
    };

    const xAxisLabel = period === 'lastday' ? 'Hours of the Day' : 'Day';
    const yAxisLabel = 'Consumption (kWh)';

    const option = {
        tooltip: {trigger: 'axis', axisPointer: {type: 'shadow'}},
        legend: {data: ['Consumption (kWh)'], formatter: () => xAxisLabel},
        grid: {left: '3%', right: '4%', bottom: '3%', containLabel: true},
        xAxis: [{type: 'category', data: labels, axisTick: {alignWithLabel: true}, name: xAxisLabel}],
        yAxis: [{type: 'value', name: yAxisLabel}],
        series: [
            {
                name: 'Consumption (kWh)',
                type: 'bar',
                barWidth: '60%',
                data: data,
                itemStyle: {color: (params) => getGradientColor(params.value)}
            }
        ]
    };

    return (
        <Grid container spacing={2} sx={{display: 'flex', alignItems: 'stretch', mt: 1, mb: 5}}>
            <Grid item xs={12} md={9} sx={{display: 'flex', flexDirection: 'column', order: {xs: 1, md: 2}}}>
                <Paper elevation={0}
                       style={{padding: '20px', borderRadius: '25px', backgroundColor: 'white', height: '100%'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant="h5" sx={{fontWeight: 'bold'}} className={'fancyText'}>Periodic Consumption</Typography>
                        <FormControl variant="outlined" style={{minWidth: 120}}>
                            <InputLabel>Period</InputLabel>
                            <Select value={period} onChange={handleChange} label="Period">
                                <MenuItem value='lastday'>Last Day</MenuItem>
                                <MenuItem value='lastweek'>Last Week</MenuItem>
                                <MenuItem value='lastmonth'>Last Month</MenuItem>
                                <MenuItem value='customperiod'>Custom Period</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    {period === 'customperiod' && (
                        <div style={{ display: 'flex', gap: '10px', marginTop: '20px', justifyContent: 'flex-end' }}>
                            <TextField
                                label="Starting Date"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                size="small" // Makes the input smaller
                                sx={{ maxWidth: 150 }} // Limits width
                            />
                            <TextField
                                label="Ending Date"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                size="small" // Makes the input smaller
                                sx={{ maxWidth: 150 }} // Limits width
                            />
                            <Button
                                variant="contained"
                                onClick={fetchCustomPeriodData}
                                disabled={!startDate || !endDate}
                                style={{ alignSelf: 'center', padding: '6px 12px', fontSize: '0.75rem' }}
                            >
                                Retrieve Data
                            </Button>
                        </div>
                    )}

                    <div style={{flex: 1, width: '100%'}}>
                        {loading && <Loading/>}
                        {!loading && dataError && <Alert severity="error" sx={{mt: 2}}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong while fetching the data. Please try again later.
                        </Alert>}
                        {!loading && !dataError && <ReactECharts option={option} style={{width: '100%'}}/>}
                    </div>
                </Paper>
            </Grid>

            <Grid item xs={12} md={3} sx={{display: 'flex', flexDirection: 'column', order: {xs: 2, md: 1}}}>
                <Paper
                    elevation={0}
                    style={{
                        padding: '20px',
                        borderRadius: '25px',
                        backgroundColor: 'white',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {loading && <div style={{margin: 'auto'}}><Loading/></div>}
                    {!loading && dataError &&
                        <Alert severity="error" sx={{mt: 2}}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong while fetching the data. Please try again later.
                        </Alert>
                    }
                    {!loading && !dataError && <>
                        <div style={{textAlign: 'center', marginTop: 'auto'}}>
                            <Typography className={'fancyText'} variant={'h1'} fontWeight={600}>
                                {average.toFixed(2)}
                            </Typography>
                            <Typography variant="subtitle1" align={'center'} sx={{color: '#666666', marginTop: '0px'}}>
                                <span style={{fontWeight: 'bold'}} className={'fancyTextSmall'}>(kWh)</span>
                            </Typography>
                        </div>

                        <Typography variant="subtitle2" align={'center'} sx={{color: '#666666', marginTop: 'auto'}}>
                            {period === 'lastday'
                                ? 'Average consumption of the day (hourly)'
                                : period === 'lastweek'
                                    ? 'Average consumption of the week (daily)'
                                    : period === 'customperiod'
                                        ? 'Average consumption in the requested period'
                                        : 'Average consumption of the month (daily)'}
                        </Typography>
                    </>}
                </Paper>
            </Grid>
        </Grid>
    );
}

export default HouseMonitoringPeriodicConsumptions;
