import React, {useState, useEffect} from 'react';
import {Box, Grid, Paper, Typography, LinearProgress, Alert, AlertTitle, Skeleton} from "@mui/material";
import {useParams} from 'react-router-dom';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import TodayIcon from '@mui/icons-material/Today';
import EventNoteIcon from '@mui/icons-material/EventNote';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {getHouseLatestMetrics} from '../../../api_calls/api_calls_houses';

const HouseMonitoringLatestMetrics = ({loadingInsights, monthInsights}) => {
    const {id} = useParams();

    const [metrics, setMetrics] = useState({
        lastHourConsumption: '',
        last24HoursConsumption: '',
        yesterdayConsumption: '',
        todayConsumption: '',
        monthlyConsumption: 450, // Default placeholder value for monthly consumption
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const monthPercentage = (monthInsights?.data?.current / monthInsights?.data?.average) * 100;

    useEffect(() => {
        setLoading(true);
        setError(false);
        getHouseLatestMetrics(id)
            .then(response => {
                setMetrics({
                    lastHourConsumption: `${response.last_hour_consumption} kWh`,
                    last24HoursConsumption: `${response.last_24_hours_consumption} kWh`,
                    yesterdayConsumption: `${response.yesterdays_consumption} kWh`,
                    todayConsumption: `${response.todays_consumption} kWh`,
                });
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    }, [id]);

    const boxStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '25px',
        width: '100%',
        gap: '10px',
    };

    return (
        <Grid container spacing={2}>
            {error ? (
                <Grid item xs={12}>
                    <Paper elevation={0} style={boxStyle}>
                        <Alert severity="error" sx={{mt: 2}}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong while fetching the data. Please try again later.
                        </Alert>
                    </Paper>
                </Grid>
            ) : (
                <>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height="100%" sx={{borderRadius: '25px'}}/>
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <AccessAlarmIcon style={{fontSize: 40, color: '#0A6BAC'}}/>
                                <Box>
                                    <Typography variant="h6">{metrics.lastHourConsumption}</Typography>
                                    <Typography variant="subtitle2">Last hour consumption</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={80} sx={{borderRadius: '25px'}}/>
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <EventAvailableIcon style={{fontSize: 40, color: '#0A6BAC'}}/>
                                <Box>
                                    <Typography variant="h6">{metrics.last24HoursConsumption}</Typography>
                                    <Typography variant="subtitle2">Last 24 hours consumption</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={80} sx={{borderRadius: '25px'}}/>
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <TodayIcon style={{fontSize: 40, color: '#0A6BAC'}}/>
                                <Box>
                                    <Typography variant="h6">{metrics.yesterdayConsumption}</Typography>
                                    <Typography variant="subtitle2">Yesterday consumption</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={80} sx={{borderRadius: '25px'}}/>
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <EventNoteIcon style={{fontSize: 40, color: '#0A6BAC'}}/>
                                <Box>
                                    <Typography variant="h6">{metrics.todayConsumption}</Typography>
                                    <Typography variant="subtitle2">Consumption today until now</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                </>
            )}

            {/* Monthly Progress Bar: Always Render */}
            <Grid item xs={12}>
                <Paper
                    elevation={0}
                    style={{
                        padding: '20px',
                        borderRadius: '25px',
                        backgroundColor: '#fff',
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h5" align="center" gutterBottom sx={{fontWeight: 'bold', mb: 3}}
                                className={'fancyText'}>
                        Monthly Consumption Insight
                    </Typography>

                    {
                        monthInsights.status === 'error' ? (
                            <Alert severity="error" sx={{mt: 2}}>
                                <AlertTitle>Error</AlertTitle>
                                Something went wrong while fetching the data. Please try again later.
                            </Alert>
                        ) : (
                            loadingInsights ? (
                                <Skeleton variant="rectangular" width="100%" height={80} sx={{borderRadius: '25px'}}/>
                            ) : (
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={Math.min(monthPercentage, 100)}
                                        sx={{
                                            width: '100%',
                                            height: 12,
                                            borderRadius: 6,
                                            backgroundColor: 'rgba(0, 171, 225, 0.2)',
                                            '& .MuiLinearProgress-bar': {
                                                backgroundImage: `linear-gradient(to right, #00ABE1, #161F6D)`,
                                            },
                                        }}
                                    />
                                    <Typography variant="body1" align="center" sx={{mt: 2}}>
                                        Consumption until today
                                        is <strong>{monthInsights.data.current} kWh</strong> while monthly
                                        average is <strong>{monthInsights.data.average} kWh</strong> (
                                        <strong
                                            style={{
                                                color: monthPercentage > 80 ? '#d32f2f' : 'inherit',
                                            }}
                                        >
                                            {monthPercentage.toFixed(1)}%
                                        </strong>
                                        )
                                    </Typography>
                                </Box>
                            )
                        )
                    }

                    <Box display="flex" alignItems="center" gap="5px" mt={1}>
                        <InfoOutlinedIcon sx={{color: '#00ABE1'}}/>
                        <Typography variant="body2" color="textSecondary" sx={{fontSize: '0.75rem', lineHeight: '1.2'}}>
                            Consumption this month compared to last month's total consumption
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default HouseMonitoringLatestMetrics;
