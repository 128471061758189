import React from 'react';
import ReactECharts from 'echarts-for-react';
import {Grid, Typography, Paper, Box, Skeleton, Alert, AlertTitle} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function HouseMonitoringAdditional({loadingInsights, dayInsights, weekInsights}) {
    console.log(dayInsights, weekInsights);

    // Percentage calculations
    const todayPercentage = (dayInsights?.data?.current / dayInsights?.data?.average) * 100;
    const weeklyPercentage = (weekInsights?.data?.current / weekInsights?.data?.average) * 100;

    // Gradient colors for the doughnut charts
    const gradientColor = {
        measured: {
            type: 'linear',
            x: 0, y: 0, x2: 1, y2: 1,
            colorStops: [
                {offset: 0, color: '#00ABE1'}, // Light blue
                {offset: 1, color: '#161F6D'}  // Dark blue
            ]
        },
        remaining: '#E0E0E0'
    };

    // Doughnut Chart: Today's Consumption
    const todayDoughnutOptions = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                if (params.name === 'Consumption') {
                    return `Consumption today vs Average Daily Consumption: ${params.value}%`;
                }
                return ''; // Hides tooltip for grey section
            }
        },
        series: [
            {
                name: "Today's Consumption",
                type: 'pie',
                radius: ['50%', '70%'],
                label: {show: false},
                labelLine: {show: false},
                data: [
                    {
                        value: todayPercentage.toFixed(2),
                        name: 'Consumption',
                        itemStyle: {color: gradientColor.measured}
                    },
                    {
                        value: (100 - todayPercentage).toFixed(2),
                        name: 'Daily Average',
                        itemStyle: {color: gradientColor.remaining}
                    }
                ]
            }
        ]
    };

    // Doughnut Chart: Weekly Consumption
    const weeklyDoughnutOptions = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                if (params.name === 'Consumption') {
                    return `Consumption this week vs Average Weekly Consumption: ${params.value}%`;
                }
                return ''; // Hides tooltip for grey section
            }
        },
        series: [
            {
                name: "Weekly Consumption",
                type: 'pie',
                radius: ['50%', '70%'],
                label: {show: false},
                labelLine: {show: false},
                data: [
                    {
                        value: weeklyPercentage.toFixed(2),
                        name: 'Consumption',
                        itemStyle: {color: gradientColor.measured}
                    },
                    {
                        value: (100 - weeklyPercentage).toFixed(2),
                        name: 'Weekly Average',
                        itemStyle: {color: gradientColor.remaining}
                    }
                ]
            }
        ]
    };

    return (
        <Paper
            elevation={0}
            style={{
                padding: '20px',
                borderRadius: '25px',
                backgroundColor: 'white',
                height: '100%',
            }}
        >
            {/* General Title */}
            <Typography
                variant="h5"
                align="center"
                sx={{fontWeight: 'bold'}}
                className={'fancyText'}
            >
                Consumption Overview
            </Typography>

            <Grid container spacing={2} alignItems="center" style={{height: '100%'}}>
                {/* Today's Consumption Doughnut Chart */}
                <Grid item xs={12} md={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    {dayInsights?.error ? (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong with today's data. Please try again later.
                        </Alert>
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <ReactECharts option={todayDoughnutOptions} style={{height: '250px', width: '100%'}}/>
                            {loadingInsights ?
                                <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: '25px'}}/>
                                :
                                <Typography align="center" variant="body1">
                                    <strong>{dayInsights.data.current} kWh</strong> (
                                    <strong
                                        style={{
                                            color: todayPercentage > 80 ? '#d32f2f' : todayPercentage > 50 ? '#ED6C02' : 'inherit',
                                        }}
                                    >
                                        {todayPercentage.toFixed(1)}%
                                    </strong> of average)
                                </Typography>}
                            {/* Subtitle with Info Icon */}
                            <Box display="flex" alignItems="center" gap="5px" mt={1}>
                                <InfoOutlinedIcon sx={{color: '#00ABE1'}}/>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    align="center"
                                    sx={{fontSize: '0.75rem', lineHeight: '1.2', mt: 0.5}}
                                >
                                    Consumption today compared to last month's average daily consumption
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Grid>

                {/* Weekly Consumption Doughnut Chart */}
                <Grid item xs={12} md={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    {weekInsights?.error ? (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong with this week's data. Please try again later.
                        </Alert>
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <ReactECharts option={weeklyDoughnutOptions} style={{height: '250px', width: '100%'}}/>
                            {loadingInsights ?
                                <Skeleton variant="rectangular" width="100%" height={30} sx={{borderRadius: '25px'}}/>
                                :
                                <Typography align="center" variant="body1">
                                    <strong>{weekInsights.data.current} kWh</strong> (
                                    <strong
                                        style={{
                                            color: weeklyPercentage > 80 ? '#d32f2f' : weeklyPercentage > 50 ? '#ED6C02' : 'inherit',
                                        }}
                                    >
                                        {weeklyPercentage.toFixed(1)}%
                                    </strong> of average)
                                </Typography>}
                            {/* Subtitle with Info Icon */}
                            <Box display="flex" alignItems="center" gap="5px" mt={1}>
                                <InfoOutlinedIcon sx={{color: '#00ABE1'}}/>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    align="center"
                                    sx={{fontSize: '0.75rem', lineHeight: '1.2', mt: 0.5}}
                                >
                                    Consumption this week compared to last week's average total consumption
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
}

export default HouseMonitoringAdditional;
